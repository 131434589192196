<template>
    <div class="auth-config">
        <div class="table-left">
            <div class="btn-box">
                <fontButton @click="goAdd">新增</fontButton>
            </div>
            <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange' @current-change="tableClick">
                <template slot="operate" slot-scope="data">
                   <span @click.stop="goEdit(data.data.row)" class="table-btn">编辑</span>
                </template>
            </ContentTable>
        </div>
        <div class="auth-tree">
            <div class="operate">
                <div class="fields">权限资源
                    <el-divider direction="vertical"></el-divider>
                    <span class="current-role" title="当前角色">{{ roleName }}</span>
                </div>
                <fontButton @click="saveRoleResource">保存</fontButton>
            </div>
            <el-tree ref="tree" :data="resourceTree" show-checkbox check-strictly
             :default-expanded-keys="defaultCheckedKeys" :default-checked-keys="defaultCheckedKeys"
             :expand-on-click-node="false"
             :node-key="treeProps.key"
             :props="treeProps"
             :filter-node-method="filterNode"
             @check="handleCheck">
                <span class="tree-node el-tree-node__label" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                </span>
            </el-tree>
        </div>
        <newAndEdit :formType="formType" :formData="formData" :show.sync="formShow" :title="formTitle" @saveData="newAndEditSave"></newAndEdit>
    </div>
</template>
<script>
import {left_nav_data} from "../../../lef-nav/leftnav";
import {tableData_auth} from "../../common/data"
import basePage from "../../common/basePage.vue"
import {ajax_get_roleList, ajax_post_roleEdit, ajax_post_roleAdd, } from "../../../../api/index"
export default {
    name:"authConfig",
    mixins: [basePage],
    data(){
        return {
            roleName:"",
            treeProps: {
                key: 'key',
                parentKey: 'parentKey',
                label: 'name',
                children: 'children',
                rootValue: '',
            },
            defaultCheckedKeys: [],
            resourceTree: [],
            currentRow:"",
            editId:""
        }
    },
    created(){
        this.pageInit();
    },
    methods:{
        pageInit(){
            this.ajaxListMethods = ajax_get_roleList;
            this.resourceTree = this.$deepClone(left_nav_data)
            this.tableData = this.$deepClone(tableData_auth)
            this.generateFormArr("auth")
            this.ajax_list_get(true)
        },
        handerTableData(list){
           let resList = [];
           list.forEach((element,index)=>{
              element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
              resList.push(element)
           })
           return resList;
        },
        tableClick(row){
            if(!row) return 
            this.roleName = row.roleName;
            this.currentRow = row
            if(row.roleSource){
                const authArr = row.roleSource.split(",")
                this.$refs.tree.setCheckedKeys(authArr)
            }else{
                this.$refs.tree.setCheckedKeys([])
            }
        },
        handleCheck(value){
            console.log(value,"tree")
        },
        filterNode(value, data, node) {
            if (!value) return true;
            return data.name && data.name.indexOf(value) !== -1;
        },
        goAdd(){
            this.formInit(true)
            this.editId = ""
        },
        goEdit(data){
            this.currentRow = data;
            this.formInit(false)
            this.tableToForm(data)
            this.editId = data.id
        },
        saveRoleResource(){
            const roleSource = this.$refs.tree.getCheckedKeys()
            this.currentRow.roleSource = roleSource.join(",")
            ajax_post_roleEdit(this.currentRow).then(res=>{
                if(res.code == 200){
                   this.$message.success("操作成功")
                   this.ajax_list_get();
                }
            }).catch(err=>{

            })
        },
        newAndEditSave(){
            let saveMethods = this.formType==="add" ? ajax_post_roleAdd : ajax_post_roleEdit
            const params = this.formToSubmit()
            if(this.formType==="edit") params.id = this.editId;
            params.roleSource = this.currentRow.roleSource;
            saveMethods(params).then(res=>{
                if(res.code == 200){
                   this.$message.success("操作成功")
                   this.formShow = false;
                   this.ajax_list_get();
                }
            }).catch(err=>{
                console.log("ajax_err:",err)
            })
        },
        formToSubmit(){
          let obj = {}
          this.formData.forEach(v=>{
            obj[v.id] = v.value
          })
          return obj
        }
    }
}
</script>
<style lang="less" scoped>
.auth-config{
    width: 100%;
    height: 100%;
    display: flex;
    .table-left{
        width: 60%;
        height: 100%;
        padding-right: 10px;
        box-sizing: border-box;
        .btn-box{
            text-align: right;
            margin-bottom: 10px;
        }
    }
    .auth-tree{
        width: 40%;
        height: 100%;
        border-left: 1px solid rgb(228, 231, 237);
        padding-left: 10px;
        box-sizing: border-box;
        .operate{
            display: flex;
            line-height: 30px;
            margin-bottom: 10px;
        }
    }
}
</style>